import { template as template_b0a323fa99674d168ec4d07e17a8da47 } from "@ember/template-compiler";
const FKText = template_b0a323fa99674d168ec4d07e17a8da47(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
