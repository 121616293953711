import { template as template_60680d7442d649c78cd17dc4d102b0f1 } from "@ember/template-compiler";
const FKControlMenuContainer = template_60680d7442d649c78cd17dc4d102b0f1(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
